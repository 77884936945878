import React from 'react'
import { MdPhone, MdMail } from "react-icons/md";
import { FaInstagram, FaFacebook, FaWhatsapp } from "react-icons/fa";
import { Nav, Navbar } from 'react-bootstrap';
import { Container, Row, Col } from 'react-bootstrap';
import Logo from '../img/logo.png';
import Carousel from '../components/Carousel';
import Company from '../components/Company';
import Services from '../components/Services';
import Contact from './Contact';
import Login from './Admin/Login';
// import './../styles/Header.css';
import {
	BrowserRouter as Router,
	Switch,
	Route,
} from "react-router-dom";

function Header() {
	return (
		<React.Fragment>
			<Navbar bg="light" expand="lg">
				<Navbar.Toggle aria-controls="basic-navbar-nav" />
				<Navbar.Collapse id="basic-navbar-nav">
					<Nav className="mr-auto">
						<Nav.Link href="tel:+554932462131">{<MdPhone />} (49) 3246-2131</Nav.Link>
						<Nav.Link href="mailto:comercial@hollobyte.com.br">{<MdMail />} comercial@hollobyte.com.br</Nav.Link>
					</Nav>
					<Nav>
						<ul className="mr-sm-6">
							<li><a href="https://www.instagram.com/hollobyte.informatica/" rel="noopener noreferrer" target="_blank">{<FaInstagram />}</a></li>
							<li><a href="https://www.facebook.com/hollobyte/" rel="noopener noreferrer" target="_blank">{<FaFacebook />}</a></li>
							<li><a href="https://api.whatsapp.com/send?1=pt_BR&phone=5549984015120" rel="noopener noreferrer" target="_blank">{<FaWhatsapp />}</a></li>
						</ul>
					</Nav>
				</Navbar.Collapse>
			</Navbar>
			<Container fluid className="end-1" />
			<Router>
				<Container fluid>
					<Row className="logo">
						<Col sm={3} md={3} lg={3} style={{ marginRight: '10%' }}>
							<img src={Logo} alt="logo Hollobyte" />
						</Col>
						<Navbar expand="lg">
							<Navbar.Toggle aria-controls="basic-navbar-nav" />
							<Navbar.Collapse id="basic-navbar-nav">
								<Nav className="justify-content-center" style={{ flex: 1, fontSize: 24, alignItems: 'center', justifyContent: 'center' }}>
									<Nav.Link className='link' style={{ color: '#4066A8' }} href="/">Página Inicial</Nav.Link>
									<Nav.Link className='link' style={{ color: '#4066A8' }} href="/empresa">Empresa</Nav.Link>
									<Nav.Link className='link' style={{ color: '#4066A8' }} href="/servicos">Serviços</Nav.Link>
									<Nav.Link className='link' style={{ color: '#4066A8' }} target="_blank" rel="noopener noreferrer" href="https://anydesk.com/pt/downloads/thank-you?dv=win_exe">Atendimento Remoto</Nav.Link>
									<Nav.Link className='link' style={{ color: '#4066A8' }} href="/contato">Contato</Nav.Link>
								</Nav>
							</Navbar.Collapse>
						</Navbar>
					</Row>
				</Container>
				<Container fluid className="end" />
				<Switch>
					<Route path="/empresa">
						<Company />
					</Route>
					<Route path="/servicos">
						<Services />
					</Route>
					<Route path="/contato">
						<Contact />
					</Route>
					<Route path="/admin">
						<Login />
					</Route>
					<Route path="/">
						<Carousel />
					</Route>
				</Switch>
			</Router>
		</React.Fragment>
	)
}

export default Header;
