import React, { useState, useEffect } from 'react';
import axios from 'axios'
import { Button } from 'react-bootstrap';

export default function List() {

	const [contatos, setContatos] = useState([]);

	useEffect(() => {
		async function loadContatos() {
			const response = await axios.get('https://hollobyte.herokuapp.com/contatos');
			setContatos(response.data);
		}
		loadContatos();
	}, []);

	const handleDelete = async (id) => {
		console.log(id);
		await axios.delete(`https://hollobyte.herokuapp.com/contatos/${id}`)
			.then((resp) => setContatos(resp.data));
	}

	return (
		<table className='table'>
			<thead>
				<tr>
					<th>Nome</th>
					<th>E-mail</th>
					<th>Telefome</th>
					<th>Mensagem</th>
					<th>Excluir</th>
				</tr>
			</thead>
			<tbody>
				{
					contatos.map(contato => (
						<tr key={contato._id}>
							<td>{contato.nome}</td>
							<td>{contato.email}</td>
							<td>{contato.telefone}</td>
							<td>{contato.mensagem}</td>
							<td>
								<Button onClick={() => handleDelete(contato._id)} variant="danger">Excluir</Button>
							</td>
						</tr>
					))
				}
			</tbody>
		</table>
	)
}