import React, { useState } from 'react';
import Map from './Map/Map';
import { Form, Row, Col, Button } from 'react-bootstrap';
import FlashMessage from 'react-flash-message'
import axios from 'axios';
import { isMobile } from 'react-device-detect';

const Contact = () => {

	const [nome, setNome] = useState('');
	const [email, setEmail] = useState('');
	const [telefone, setTelefone] = useState('');
	const [mensagem, setMensagem] = useState('');
	const [sent, setSent] = useState(false);

	console.log(isMobile)

	const handleSubmit = async (evt) => {
		evt.preventDefault();
		await axios.post('https://hollobyte.herokuapp.com/contatos', {
			nome,
			email,
			telefone,
			mensagem
		}).then(resp => {
			setSent(true);
			setNome('');
			setEmail('');
			setTelefone('');
			setMensagem('');
		});
	}

	return (
		<>
			{
				isMobile ? (
					<>
						<div className='mobile-data'>
							<Row>
								<h6>Telefone: <a href='tel:+554932462131'>(49) 3246-2131</a></h6>
							</Row>
							<Row>
								<h6>E-mail: <a href="mailto:comercial@hollobyte.com.br">comercial@hollobyte.com.br</a></h6>
							</Row>
							<Row>
								<h6>WhatsApp: <a href="tel:+4998401-5120">(49) 98401-5120</a></h6>
							</Row>
						</div>
						<Map />
					</>
				) : (
					<>
							<div className='data'>
								<Row>
									<h5>Telefone: <a href='tel:+554932462131'>(49) 3246-2131</a></h5>
								</Row>
								<Row>
									<h5>E-mail: <a href="mailto:comercial@hollobyte.com.br">comercial@hollobyte.com.br</a></h5>
								</Row>
								<Row>
									<h5>WhatsApp: <a href="tel:+4998401-5120">(49) 98401-5120</a></h5>
								</Row>
							</div>
						<Map className="map-desk"/>
					</>
				)
			}
		</>
	);
}

export default Contact;
