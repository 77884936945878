import React from 'react';
import { Row, Container } from 'react-bootstrap';
import '../styles/Company.css'

function Company() {
	return (
		<React.Fragment>
			<Container fluid className="company" id="empresa">
				<Row className="company-h1">
					<h1>A EMPRESA</h1>
				</Row>
				<Row>
					<p>Em 02 de Maio de 1997, na cidade de Fraiburgo, a Hollobyte Informática deu inicio a sua historia de sucesso como prestadora de serviços e fornecedor de equipamentos para informática.</p>
					<p>Conta hoje com uma equipe especializada, preocupando-se em estreitar o relacionamento com os clientes oferecendo o que um fornecedor pode fazer de melhor: excelência em atendimento, preços competitivos, pontualidade e qualidade.</p>
					<p>Confiança, Seriedade e Honestidade são princípios primordiais nas relações comerciais com {<strong>Clientes</strong>}, {<strong>Fornecedores</strong>} e {<strong>Colaboradores</strong>}.</p>
				</Row>
			</Container>
		</React.Fragment>
	)
}

export default Company;