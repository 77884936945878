import React from 'react';
import { Map, TileLayer, Marker, Popup } from 'react-leaflet';

function MapComponent() {
	return (
		<div>
			<Map center={[-27.025, -50.92]} zoom={14} >
				<TileLayer
					url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
					attribution='Map tiles by <a href="http://stamen.com">Stamen Design</a>, <a href="http://creativecommons.org/licenses/by/3.0">CC BY 3.0</a> &mdash; Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
					noWrap={true} />
				<Marker
					onMouseOver={(e) => {
						e.target.openPopup();
					}}
					onMouseOut={(e) => {
						e.target.closePopup();
					}}
					position={[-27.0267348, -50.9220748, 21]}
				>
					<Popup>Rua Nereu Ramos, 471 - Centro, Fraiburgo - SC</Popup>
				</Marker>
			</Map>
		</div>
	);
}

export default MapComponent;