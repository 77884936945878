import React, { useState } from 'react';
// import Carousel from 'react-bootstrap/Carousel'

import base64 from '../base64';

function Carrousel() {

	// const [index, setIndex] = useState(0);
	// const [direction, setDirection] = useState(null);

	// const handleSelect = (selectedIndex, e) => {
	//     setIndex(selectedIndex);
	//     setDirection(e.direction);
	// };

	return (
		// <Carousel className="image-carrousel" activeIndex={index} direction={direction} interval={7500} onSelect={handleSelect}>
		//     <Carousel.Item>
		//         <img
		//             className="image-carrousel"
		//             src={Image7}
		//             alt="Primeira imagem"
		//         />
		//     </Carousel.Item>
		// </Carousel>
		<img className="image-carrousel" src={`data:image/png;base64, ${base64.value}`} alt="Imagem de computador e lápis" />
	)
}

export default Carrousel;