import React from 'react';
import '../styles/Services.css';
import { Container, Col, Row, Image } from 'react-bootstrap';

import Image1 from '../img/1.svg';
import Image2 from '../img/2.svg';
import Image3 from '../img/3.svg';
import Image4 from '../img/4.svg';

function Services() {
	return (
		<React.Fragment>
			<Container fluid>
				<Row style={{ margin: '2.5%' }}>
					<Col className='featureText'>
						<Row><h5>Assistência técnica especializada em manutenção de computadores, impressoras matriciais, jato de tinta e laser, impressoras multifuncionais, nobreak e monitores</h5></Row>
					</Col>
					<Col xs={{ order: 'first' }} md={{ order: 'last' }}>
						<Row><Image src={Image2} alt='Imagem ilustrátiva 1' fluid style={{ margin: 'auto', width: '30%' }} /></Row>
					</Col>
				</Row>
				<Row style={{ margin: '2.5%' }}>
					<Col xs={{ order: 'last' }} md={{ order: 'first' }}>
						<Row><Image src={Image1} alt='Imagem ilustrátiva 2' fluid style={{ margin: 'auto', width: '30%' }} /></Row>
					</Col>
					<Col>
						<Row className='featureTextRight'>
							<h5>Manutenção preventiva</h5>
							<h6>A manutenção preventiva consiste na efetivação de regulagens, ajustes, lubrificação e limpeza interna dos equipamentos.</h6>
						</Row>
						<Row className='featureTextRight'>
							<h5>Manutenção corretiva</h5>
							<h6>A manutenção corretiva consiste na correção de eventuais falhas dos equipamentos, mediante as necessárias substituições de módulos, peças ou componentes que se apresentam defeito no seu funcionamento.</h6>
						</Row>
					</Col>
				</Row>
				<Row style={{ margin: '2.5%' }}>
					<Col xs={{ order: 'first' }} md={{ order: 'last' }}>
						<Row><Image src={Image3} alt='Imagem ilustrátiva 3' fluid style={{ margin: 'auto', width: '30%' }} /></Row>
					</Col>
					<Col>
						<Row className='featureTextLeft'>
							<h5>Instalações e configuração de redes</h5>
							<h6>Arquitetura, instalação e manutenção. Instalação, configuração e manutenção de equipamentos - Switches, Routers, Gateways, Bridges, Hubs. Distribuição de cabos - Desenho e instalação de infra-estrutura física de rede, distribuição de cabos, instalação de pontos de rede, certificação de redes, etiquetagem de redes, gestão de cablagem/patches.</h6>
						</Row>
					</Col>
				</Row>
				<Row style={{ margin: '2.5%' }}>
					<Col xs={{ order: 'last' }} md={{ order: 'first' }}>
						<Row><Image src={Image4} alt='Imagem ilustrátiva 4' fluid style={{ margin: 'auto', width: '30%' }} /></Row>
					</Col>
					<Col>
						<Row className='featureTextRight'>
							<h5>Contrato de manutenção e Locação de equipamentos</h5>
							<h6>Manutenções preventivas agendadas. Disponibilidade de equipamento de back-up. Prioridade nos atendimentos.</h6>
						</Row>
					</Col>
				</Row>
			</Container>
		</React.Fragment>

	)
}

export default Services;