import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import axios from 'axios';
import List from './List';
import FlashMessage from 'react-flash-message'

export default function Login() {

	const [usuario, setUsuatio] = useState('');
	const [senha, setSenha] = useState('');
	const [logged, setLogged] = useState('');

	const handleSubmit = async (evt) => {
		evt.preventDefault();
		await axios.get(`https://hollobyte.herokuapp.com/usuario/${usuario}/${senha}`)
			.then(resp => {
				setUsuatio('');
				setSenha('');
				setLogged('logged');
			}).catch(error => {
				setLogged('false');
			});
	}

	return (
		<><div>
			{logged === 'false' ? (
				<FlashMessage duration={5000}>
					<strong>Usuário ou senha incorreto!</strong>
				</FlashMessage>
			) : null}
			{
				logged === 'logged' ? (
					<List />
				) : (
					<div className='login'>
						<Form onSubmit={handleSubmit}>
							<Form.Group controlId="formBasicEmail">
								<Form.Control type="text" placeholder="Usuário" value={usuario} onChange={e => setUsuatio(e.target.value)} />
								<Form.Text className="text-muted">
								</Form.Text>
							</Form.Group>

							<Form.Group controlId="formBasicPassword">
								<Form.Control type="password" placeholder="Senha" value={senha} onChange={e => setSenha(e.target.value)} />
							</Form.Group>
							<Button variant="primary" type="submit">
								Entrar
							</Button>
						</Form>
					</div>
				)
			}
		</div></>
	)
}